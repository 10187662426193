import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../views/Index.vue';
import DePK10 from '../views/DePK10.vue';
import Keno from '../views/Keno.vue';
import Lotto6out49 from '../views/Lotto6out49.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/DePK10',
    name: 'DePK10',
    component: DePK10
  },
  {
    path: '/Keno',
    name: 'Keno',
    component: Keno
  },
  {
    path: '/Lotto6out49',
    name: 'Lotto6out49',
    component: Lotto6out49
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;