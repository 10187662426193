<template>
  <div>
    <div class="MainBn">
      <ul class="bxslider">
        <li>
          <router-link class="BnNews" :to="{ name:'Lotto6out49' }">
            <div>
              <h1 class="BnNewsTitle">
                <span v-if="!lotto6out49.jackpot">Das Original!</span>
                <span v-else>{{lotto6out49.jackpot | currency}}€</span>
              </h1>
              <h2 class="BnContent">
                <span class="DarkYellow">Diesen Samstag im Jackpot!</span>
                <br>
                <span class="DarkPink">Chance1:140 Mio., Kl. 1. Ab 18 J.</span>
              </h2>
              <img src="../assets/images/main_bn01.jpg">
            </div>
          </router-link>
        </li>
        <li>
          <router-link class="BnNews" :to="{ name:'Keno' }">
            <div>
              <h1 class="BnNewsTitle"><span class="DarkBrown">1Milo.€</span></h1>
              <h2 class="BnContent">
                <span class="DarkYellow">Diesen Samstag im Jackpot!</span>
                <br>
                <span class="DarkPink">Chance1:2,2 Mio., KENO-Typ 10. Ab 18 J.</span>
              </h2>
              <img src="../assets/images/main_bn02.jpg">
            </div>
          </router-link>
        </li>
        <li>
          <router-link class="BnNews" :to="{ name:'DePK10' }">
            <div>
              <h1 class="BnNewsTitle"><span>100,000.€</span></h1>
              <h2 class="BnContent">
                <span class="DarkYellow">Diesen Minute im Jackpot!</span>
                <br>
                <span class="DarkPink">Chance 1:3,6 Mio., Kl. 1.Ab J.</span>
              </h2>
              <img src="../assets/images/main_bn03.jpg">
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="container">
      <ol class="GameBox">
        <li class="GameFrame GameSpacing">
          <div class="LottoBonusBg">
            <p class="bonus">
              <span class="Money" v-if="!lotto6out49.jackpot">Das Original!</span>
              <span class="Money" v-else>{{lotto6out49.jackpot | currency}}</span><span class="MoneyUnit ">.€</span>
              <span class="ClearBoth"></span>
            </p>
            <p class="BonusTitle Brown">Heute im Jackpot!</p>
            <p class="BonusDepiction">Chance1:140 Mio., Kl. 1. Ab 18 J.</p>
          </div>
          <ul class="Lottoinfor">
            <li class="NumTitle"><span>Superzahl</span></li>
            <li class="Date DarkGray">
              <span>{{lotto6out49.issueNo | formatDateTime('DD MMMM YYYY')}}</span>
            </li>
            <li class="NumFrame ClearBoth">
              <p class="Number">
                <span v-for="n in lotto6out49.lottoNumbers">
                  {{n}}
                </span>
                <span>
                  {{lotto6out49.lottoGreatNum}}
                </span>
              </p>
            </li>
          </ul>
          <p class="GoToAllNumberFrame">
            <router-link :to="{ name:'Lotto6out49' }">
              <span class="GoToAll">Alle Gewinnzahlen &gt;&gt;</span>
            </router-link>
          </p>
        </li>
        <li class="GameFrame GameSpacing">
          <div class="KenoBonusBg">
            <p class="bonus"><span class="Money Whtie">1</span><span class="MoneyUnit Whtie">Milo.€</span><span class="ClearBoth"></span></p>
            <p class="BonusTitle Purple">Heute im Jackpot!</p>
            <p class="BonusDepiction DarkGray">Chance1:2,2 Mio., KENO-Typ 10. Ab 18 J.</p>
          </div>
          <ul class="Kenoinfor">
            <li class="NumTitle"><span>Gewinnzahlen vom</span></li>
            <li class="Date DarkGray">
              <span>{{keno.issueNo | formatDateTime('DD MMMM YYYY')}}</span>
            </li>
            <li class="NumFrame ClearBoth">
              <p class="Number">
                <span v-for="number in keno.kenoNumbers">
                  {{number}}
                </span>
              </p>
            </li>
          </ul>
          <p class="GoToAllNumberFrame">
            <router-link :to="{ name:'Keno' }">
              <span class="GoToAll">Alle Gewinnzahlen &gt;&gt;</span>
            </router-link>
          </p>
        </li>
        <li class="GameFrame GameSpacing">
          <div class="PK10BonusBg">
            <p class="bonus"><span class="Money Whtie">100,000</span><span class="MoneyUnit Whtie">.€</span><span class="ClearBoth"></span></p>
            <p class="BonusTitle Aqua">Heute im Jackpot!</p>
            <p class="BonusDepiction DarkGray">Chance 1:3,6 Mio., Kl. 1.Ab J.</p>
          </div>
          <ul class="PK10infor">
            <li class="NumTitle"><span>Gewinnzahlen vom 01.</span></li>
            <li class="Date DarkGray">
              <span>{{latestCurentIssueDateTime | formatDateTime('MMMM YYYY')}} </span>
            </li>
            <li class="NumFrame ClearBoth">
              <p class="Number">
                <span v-for="(n ,i) in latestCurrentDrawNumbers" :class="'No-' + (i + 1)">
                  {{n}}
                </span>
              </p>
            </li>
          </ul>
          <p class="GoToAllNumberFrame">
            <router-link :to="{ name:'DePK10' }">
              <span class="GoToAll">Alle Gewinnzahlen &gt;&gt;</span>
            </router-link>
          </p>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import service from '../service';

export default {
  data() {
    return {
      currentIssueNo: { drawNumbers: [], currentIssueDateTime: '', prevDrawNumbers: [], prevIssueDateTime: '' },
      lotto6out49: { additionalNum: 0, insertTime: '', issueNo: '', jackpot: 0, lottoGreatNum: 0, lottoNumbers: [], quotes: [], stake: 0 },
      keno: { id: '', issueNo: '', year: '', month: '', no: 0, kenoNumbers: [], insertTime: '' }
    };
  },
  methods: {
    async getLatestLotto6out49Async() {
      var result = await service.getLatestLotto6out49Async();

      if (result)
        this.lotto6out49 = result;
    },
    async getKenoAsync() {
      var result = await service.getLatestKenoAsync();

      if (result)
        this.keno = result;
    },
    async getCurrentIssueNoAsync() {
      var result = await service.getCurrentIssueAsync();

      if (result) {
        this.currentIssueNo.drawNumbers = result.drawNumbers;
        this.currentIssueNo.currentIssueDateTime = result.currentIssueDateTime;
        this.currentIssueNo.prevDrawNumbers = result.prevDrawNumbers;
        this.currentIssueNo.prevIssueDateTime = result.prevIssueDateTime;
      }
    },
    async initAsync() {
      await Promise.all([this.getKenoAsync(), this.getLatestLotto6out49Async(), this.getCurrentIssueNoAsync()]);
    }
  },
  async mounted() {
    var slider = $('.bxslider').bxSlider({
      auto: true,
      pause: 3000,
      speed: 1600
    });

    await this.initAsync();

    slider.reloadSlider();
  },
  computed: {
    latestCurentIssueDateTime() {
      return this.currentIssueNo.drawNumbers.length ? this.currentIssueNo.currentIssueDateTime : this.currentIssueNo.prevIssueDateTime;
    },
    latestCurrentDrawNumbers() {
      return this.currentIssueNo.drawNumbers.length ? this.currentIssueNo.drawNumbers : this.currentIssueNo.prevDrawNumbers;
    }
  },
}
</script>
