<template>
  <div>
    <div class="depk10-FrameTop">
      <div class="logo-depk10">
        <img src="../assets/images/logo_depk10.png" width="317" height="74" alt="">
      </div>
      <div class="title-depk10 Yellow">
        <hr color="#edc51e" size="1" class="hrlien-L">
        <div class="title-text">Gewinnzahlen &amp; Quoten</div>
        <hr color="#edc51e" size="1" class="hrlien-R">
        <span class="ClearBoth"></span>
      </div>
      <ul class="NumFrame-depk10">
        <li class="numData" v-for="n in latestCurrentDrawNumbers">
          <span :class="'No-' + parseInt(n)">
            {{n}}
          </span>
        </li>
        <li class="ClearBoth"></li>
      </ul>
    </div>
    <div class="depk10-FrameBottom">
      <div class="list-main-frame-depk10">
        <div class="list-frame-depk10">
          <div class="abgne_tab">
            <ul class="tabs ">
              <li :class="{ 'active': isCurrentTabIndex(0)}">
                <a href="javascript:void(0);" @click="changeTabIndex(0)">
                  Historischen Gewinnzahlen
                </a>
              </li>
              <li :class="{ 'active': isCurrentTabIndex(1)}">
                <a href="javascript:void(0);" @click="changeTabIndex(1)">
                  Statistik von Häufigkeit
                </a>
              </li>
            </ul>
            <span class="ClearBoth"></span>
            <div class="tab_container">
              <div v-show="isCurrentTabIndex(0)" class="tab_content">
                <div class="YMD-frame">
                  <div class="text-YMD">Andere Ziehung wählen:</div>
                  <form>
                    <select class="select-Y" v-model="year" @change="changeYear">
                      <option value="" class="yearItem">Bitte wählen Sie</option>
                      <option v-for="y in yearSelectOptions" :key="y" :value="y" class="yearItem">
                        {{y}}
                      </option>
                    </select>
                    <select class="select-M" v-model="month" @change="changeMonth">
                      <option value="" class="monthItem">Bitte wählen Sie</option>
                      <option v-for="m in monthSelectOptions" :key="m.value" :value="m.value" class="monthItem">
                        {{m.text}}
                      </option>
                    </select>
                    <select class="select-D" v-model="day" @change="changeDay">
                      <option value="" class="dayItem">Bitte wählen Sie</option>
                      <option v-for="d in daysSelectOptions" :key="d" class="dayItem" :value="d">
                        {{d}}
                      </option>
                    </select>
                    <select class="select-T" v-model="time" @change="changeTime">
                      <option value="" class="dayItem">Bitte wählen Sie</option>
                      <option v-for="t in timeSelectOptions" :key="t.value" class="dayItem" :value="t.value">
                        {{t.text}}
                      </option>
                    </select>
                  </form>
                  <span class="ClearBoth"></span>
                </div>

                <table class="t-table" border="0" cellspacing="1" cellpadding="1" align="center">
                  <thead class="t-head">
                    <tr>
                      <td>Gewinnklasse</td>
                      <td>Richtige Zahlen</td>
                      <td>AnzahlGewinne</td>
                      <td>Erste-und-Zweite-Addition</td>
                      <td>Drache-und-Tiger </td>
                    </tr>
                  </thead>
                  <tbody class="t-body">
                    <tr class="t-single" v-if="!history.length">
                      <td colspan="5">Warten Sie Ziehung</td>
                    </tr>
                    <tr v-else class="t-single" spellcheck="false" v-for="item in history">
                      <td>{{item.issueNo}}</td>
                      <td>{{item.issueEndTime  | formatDateTime('YYYY-MM-DD')}}</td>
                      <td>
                        <span v-for="n in item.drawNumbers" class="table_Num" :class="'No-' + parseInt(n)">
                          {{n}}
                        </span>
                      </td>
                      <td class="t-align-L">
                        <span class="Red">
                          {{getHistoryAddition(item.drawNumbers)}}
                        </span> - {{getHistoryBigOrSmallText(item.drawNumbers)}} - {{getHistorySingleOrDoubleText(item.drawNumbers)}}
                      </td>
                      <td>{{getHistoryTigerOrDragonText(item.drawNumbers)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-show="isCurrentTabIndex(1)" class="tab_content">
                <div class="title-depk10">
                  <hr color="#000" size="1" class="hrlien-L">
                  <div class="title-text">Gewinnzahlen &amp; Quoten</div>
                  <hr color="#000" size="1" class="hrlien-R">
                  <span class="ClearBoth"></span>
                </div>
                <ul class="NumFrame-depk10-count" v-if="statistic">
                  <li v-for="n in statistic.numbers">
                    <span>{{n}}</span>
                  </li>
                  <li class="ClearBoth"></li>
                </ul>
                <ul class="NumFrame-depk10">
                  <li><span class="No-1">1</span></li>
                  <li><span class="No-2">2</span></li>
                  <li><span class="No-3">3</span></li>
                  <li><span class="No-4">4</span></li>
                  <li><span class="No-5">5</span></li>
                  <li><span class="No-6">6</span></li>
                  <li><span class="No-7">7</span></li>
                  <li><span class="No-8">8</span></li>
                  <li><span class="No-9">9</span></li>
                  <li><span class="No-10">10</span></li>
                  <li class="ClearBoth"></li>
                </ul>
                <div class="title-depk10">
                  <hr color="#000" size="1" class="hrlien-L">
                  <div class="title-text">Klassement-Trend</div>
                  <hr color="#000" size="1" class="hrlien-R">
                  <span class="ClearBoth"></span>
                </div>
                <div class="inside_abgne_tab">
                  <ul class="tabs sub-tabs">
                    <li v-for="i in statisticTabCount" :class="{'active':isCurrentStatisticTabIndex(i-1)}" @click="changeStatisticTabIndex(i-1)">
                      <a href="javascript:void(0);">
                        {{(i-1)===0?'Champ': i + '. Platz. '}}
                      </a>
                    </li>
                  </ul>
                  <span class="ClearBoth"></span>
                  <div class="sub-tab_container">
                    <div v-for="i in statisticTabCount" v-show="isCurrentStatisticTabIndex(i-1)" class="tab_content" :ref="'chartContainer' + (i-1)">
                      {{i}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="ClearBoth D-Block"></span>
    </div>
  </div>
</template>

<script>
import service from '../service';

export default {
  data() {
    return {
      year: '',
      month: '',
      day: '',
      time: '',
      history: [],
      tabIndex: 0,
      statistic: null, // {issueNo:'',numbers:[]}
      statisticTabIndex: 0,
      statisticTabCount: 10,
      currentIssueNo: { drawNumbers: [], currentIssueDateTime: '', prevDrawNumbers: [], prevIssueDateTime: '' }
    };
  },
  methods: {
    isCurrentTabIndex(index) {
      return index === this.tabIndex;
    },
    changeTabIndex(index) {
      this.tabIndex = index;
    },
    isCurrentStatisticTabIndex(index) {
      return index === this.statisticTabIndex;
    },
    changeStatisticTabIndex(index) {
      this.statisticTabIndex = index;
    },
    async getCurrentIssueNoAsync() {
      let result = await service.getCurrentIssueAsync();

      if (result) {
        this.currentIssueNo.drawNumbers = result.drawNumbers;
        this.currentIssueNo.currentIssueDateTime = result.currentIssueDateTime;
        this.currentIssueNo.prevDrawNumbers = result.prevDrawNumbers;
        this.currentIssueNo.prevIssueDateTime = result.prevIssueDateTime;
      }

      if (this.latestCurentIssueDateTime) {
        var moment = this.$momentTz(this.latestCurentIssueDateTime);
        this.year = moment.year().toString();
        this.month = moment.month().toString();
        this.day = moment.date().toString();
        this.time = moment.hour().toString();

        this.getHistoryAsync();
      }
    },
    changeYear() {
      this.month = '';
      this.day = '';
      this.time = '';
    },
    changeMonth() {
      this.day = ''
      this.time = '';
    },
    changeDay() {
      this.time = '';
    },
    changeTime() {
      this.getHistoryAsync();
    },
    async getHistoryAsync() {
      if (!this.year || !this.month || !this.day || !this.time)
        return [];

      var startTime = this.$momentTz([this.year, this.month, this.day, this.time]).utc().subtract(90, 'seconds'); // 扣秒數因為API的關係
      var endTime = startTime.clone().add(1, 'hours');

      startTime = startTime.format();
      endTime = endTime.format();

      var result = await service.getHistoryAsync(startTime, endTime);
      this.history = result.collection;
    },
    getHistoryAddition(drawNumbers) {
      var first = drawNumbers[0];
      var second = drawNumbers[1];

      return parseInt(first) + parseInt(second);
    },
    getHistoryBigOrSmallText(drawNumbers) {
      return this.getHistoryAddition(drawNumbers) > 11 ? 'groß' : 'klein';
    },
    getHistorySingleOrDoubleText(drawNumbers) {
      return this.getHistoryAddition(drawNumbers) % 2 === 0 ? 'gerade' : 'ungerade';
    },
    getHistoryTigerOrDragonText(drawNumbers) {
      var result = [];

      for (let i = 0; i < drawNumbers.length / 2; i++) {
        var n1 = parseInt(drawNumbers[i]);
        var n2 = parseInt(drawNumbers[drawNumbers.length - 1 - i]);

        result.push(n1 > n2 ? 'D' : 'T');
      }

      return result.join(' ');
    },
    async getStatisticAsync() {
      var result = await service.getDePk10StatisticAsync();
      this.statistic = result;
    },
    async getTrendDataAsync() {
      var result = await service.getTrendDataAsync();

      var xAxis = []; // 軸
      var data = []; // 巢狀陣列

      // 整成套件格式
      for (var i = 0; i < result.length; i++) {
        xAxis.push(result[i][0]);

        for (var j = 1; j <= this.statisticTabCount; j++) {
          (data[j - 1] = data[j - 1] || []).push(parseInt(result[i][j]));
        }
      }
      
      // render
      for (let i = 0; i < this.statisticTabCount; i++) {
        var container = this.$refs['chartContainer' + i][0];

        new Highcharts.Chart({
          chart: {
            renderTo: container, //图表的页面显示容器(也就是要显示到的div)
            defaultSeriesType: 'line', //图表类型(line、spline、scatter、splinearea、bar、pie、area、column
            width: 1133 // 寫死
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: xAxis
          },
          yAxis: {
            categories: ['', 'no.1', 'no.2', 'no.3', 'no.4', 'no.5', 'no.6', 'no.7', 'no.8', 'no.9', 'no.10'],
            title: { text: '' }
          },
          credits: {
            enabled: false
          },
          series: [{
            name: i === 0 ? 'Champ' : i + '.Platz.',
            data: data[i]
          }]
        });
      }
    }
  },
  created() {
    this.getCurrentIssueNoAsync();
  },
  mounted() {
    this.getStatisticAsync();
    this.getTrendDataAsync();
  },
  computed: {
    latestCurentIssueDateTime() {
      return this.currentIssueNo.drawNumbers.length ? this.currentIssueNo.currentIssueDateTime : this.currentIssueNo.prevIssueDateTime;
    },
    latestCurrentDrawNumbers() {
      return this.currentIssueNo.drawNumbers.length ? this.currentIssueNo.drawNumbers : this.currentIssueNo.prevDrawNumbers;
    },
    yearSelectOptions() {
      if (!this.latestCurentIssueDateTime)
        return [];

      var end = new Date(this.latestCurentIssueDateTime).getFullYear();
      var start = 2014;
      var result = [];

      for (let index = start; index <= end; index++)
        result.push(index.toString());

      return result;
    },
    monthSelectOptions() {
      if (!this.year)
        return [];

      var source = this.$dateTimeUtility.months().map((x, i) => ({
        text: x,
        value: i.toString()
      }));

      return source;
    },
    daysSelectOptions() {
      if (!this.year || !this.month)
        return [];

      var start = 1;
      var end = this.$dateTimeUtility.days(this.year, this.month);
      var result = [];

      for (let index = start; index <= end; index++)
        result.push(index.toString());

      return result;
    },
    timeSelectOptions() {
      if (!this.year || !this.month || !this.day)
        return [];

      let result = [];

      for (let i = 0; i <= 23; i++) {
        let hourText = i < 10 ? '0' + i : i;
        let timeText = hourText + ':00';
        result.push({ text: timeText, value: i.toString() });
      }

      return result;
    }
  }
}
</script>

<style scoped src="../assets/css/normal-depk10.css">
</style>