<template>
  <div class="container">
    <div class="frame-keno">
      <div class="logo-keno"> </div>
      <div class="title-keno Yellow">
        <hr color="#edc51e" size="1" class="hrlien-L">
        <div class="title-text">Gewinnzahlen &amp; Quoten</div>
        <hr color="#edc51e" size="1" class="hrlien-R">
        <span class="ClearBoth"></span>
      </div>
      <div class="YMD-frame">
        <div class="text-YMD Whtie">Andere Ziehung wählen:</div>
        <form>
          <select name="year" class="select-Y" v-model="year" @change="changeYear">
            <option value="" class="yearItem">Bitte wählen Sie</option>
            <option v-for="y in yearSelectOptions" :key="y" :value="y" class="yearItem">
              {{y}}
            </option>
          </select>
          <select name="month" class="select-M" v-model="month" @change="changeMonth">
            <option value="" class="monthItem">Bitte wählen Sie</option>
            <option v-for="m in monthSelectOptions" :key="m.value" :value="m.value" class="monthItem">
              {{m.text}}
            </option>
          </select>
          <select name="day" class="select-D" v-model="day" @change="changeDay">
            <option value="" class="dayItem">Bitte wählen Sie</option>
            <option v-for="d in daysSelectOptions" :key="d" class="dayItem" :value="d">
              {{d}}
            </option>
          </select>
        </form>
        <span class="ClearBoth"></span>
      </div>
      <div class="infor_none" v-if="!keno"><span>Warten Sie Ziehung</span></div>
      <ul class="NumFrame-keno" v-else>
        <li class="numData" v-for="n in keno.kenoNumbers">
          <span>{{n}}</span>
        </li>
        <li class="ClearBoth"></li>
      </ul>
      <div class="title-keno">
        <hr color="#edc51e" size="1" class="hrlien-L">
        <div class="title-text title-2 Yellow">Zahlenstatistik</div>
        <hr color="#edc51e" size="1" class="hrlien-R">
        <span class="ClearBoth"></span>
      </div>
      <div class="content">
        <p>
          <span class="Whtie">Hier sehen Sie, wie oft die jeweilige Zahl in den bisherigen KENO-Ziehungen gezogen wurde.</span><br>
          <span class="PinkPurole" v-if="statistics">
            {{statistics.info}}
          </span>
        </p>
      </div>
      <div class="list-frame-keno">
        <div class="abgne_tab">
          <ul class="tabs ">
            <li :class="{'active':isCurrentStatisticTabIndex(0)}">
              <a href="javascript:void(0);" @click="changeStatisticTabIndex(0)">
                Gewinnzahl
              </a>
            </li>
            <li :class="{'active':isCurrentStatisticTabIndex(1)}">
              <a href="javascript:void(0);" @click="changeStatisticTabIndex(1)">
                Häufigkeit
              </a>
            </li>
          </ul>
          <span class="ClearBoth"></span>
          <div class="tab_container">
            <div class="tab_content" v-show="isCurrentStatisticTabIndex(0)">
              <ol class="all-num-frame" v-if="statistics">
                <li v-for="g in statisticsWinningNumberGroups">
                  <template v-for="item in g">
                    <div class="num-type">
                      <span class="ball-of-num">
                        {{item.num}}
                      </span>
                      <span class="bar-chart">
                        <span class="bar-chart-percentage" :style="{ width: ((item.count / statistics.max) * 100) + '%' }"></span>
                      </span>
                      <span class="nr">
                        {{item.count}}
                      </span>
                    </div>
                  </template>
                </li>
                <span class="ClearBoth D-Block"></span>
              </ol>
            </div>
            <div class="tab_content" v-show="isCurrentStatisticTabIndex(1)">
              <ol class="all-num-frame" v-if="statistics">
                <li v-for="g in statisticsFrequencyGroups">
                  <template v-for="item in g">
                    <div class="num-type">
                      <span class="ball-of-num">
                        {{item.num}}
                      </span>
                      <span class="bar-chart">
                        <span class="bar-chart-percentage" :style="{ width: ((item.count / statistics.max) * 100) + '%' }"></span>
                      </span>
                      <span class="nr">
                        {{item.count}}
                      </span>
                    </div>
                  </template>
                </li>
                <span class="ClearBoth D-Block"></span>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import service from '../service';

export default {
  data() {
    return {
      year: '',
      month: '',
      day: '',
      keno: null,//{ id: '', issueNo: '', year: '', month: '', no: 0, kenoNumbers: [], insertTime: '' }
      statisticTabIndex: 0,
      statistics: null // { info:'', frequency:[{num: "xx", count: "xx"}...] , max:xxx,winningNumber:[{num: "xx", count: "xx"}...] }
    };
  },
  methods: {
    isCurrentStatisticTabIndex(index) {
      return index === this.statisticTabIndex;
    },
    changeStatisticTabIndex(index) {
      this.statisticTabIndex = index;
    },
    async getLatestKenoAsync() {
      var result = await service.getLatestKenoAsync();

      if (result) {
        this.year = result.year;
        this.month = parseInt(result.month) - 1;
        this.day = new Date(result.issueNo).getDate();
      }

      this.keno = result;
    },
    async getStatisticAsync() {
      var result = await service.getKenoStatisticAsync();
      this.statistics = result;
    },
    changeYear() {
      this.month = '';
      this.day = '';
    },
    changeMonth() {
      this.day = ''
    },
    async changeDay() {
      var year = this.year;
      var month = (parseInt(this.month) + 1).toString();
      var day = this.day;

      if (!year || !month || !day)
        return;

      var result = await service.getKenoByDateAsync(year, month, day);
      this.keno = result;
    },
    async initAsync() {
      await Promise.all([this.getLatestKenoAsync(), this.getStatisticAsync()]);
    },
    groupStatistic(source, itemCountPerGroup) {
      if (!source)
        return [];

      var groupCount = Math.ceil(source.length / itemCountPerGroup);

      var result = [];

      for (var i = 0; i < groupCount; i++) {
        var sliceStart = i * itemCountPerGroup;
        var sliceEnd = sliceStart + itemCountPerGroup;

        result.push(source.slice(sliceStart, sliceEnd));
      }

      return result;
    }
  },
  created() {
    this.initAsync();
  },
  computed: {
    yearSelectOptions() {
      var start = 1994;
      var end = 2018;
      var result = [];

      for (var index = start; index <= end; index++)
        result.push(index.toString());

      return result;
    },
    monthSelectOptions() {
      if (!this.year)
        return [];

      var source = this.$dateTimeUtility.months().map((x, i) => ({
        text: x,
        value: i.toString()
      }));

      return source;
    },
    daysSelectOptions() {
      if (!this.year || !this.month)
        return [];

      var start = 1;
      var end = this.$dateTimeUtility.days(this.year, this.month);
      var result = [];

      for (var index = start; index <= end; index++)
        result.push(index.toString());

      return result;
    },
    statisticsWinningNumberGroups() {
      if (!this.statistics)
        return [];

      var itemCountPerGroup = 18;
      return this.groupStatistic(this.statistics.winningNumber, itemCountPerGroup);
    },
    statisticsFrequencyGroups() {
      if (!this.statistics)
        return [];

      var itemCountPerGroup = 18;
      return this.groupStatistic(this.statistics.frequency, itemCountPerGroup);
    },
  }
}
</script>

<style scoped src="../assets/css/normal-keno.css">
</style>