<template>
  <div>
    <div id="normal-layout">
      <div class="wrap">
        <div class="skybar">
          <ul class="menu">
            <li class="logo">
              <router-link :to="{ name:'Index' }"></router-link>
            </li>
            <li class="btn btnSpacing">
              <router-link :to="{ name:'Lotto6out49' }">LOTTO 6aus49</router-link>
            </li>
            <li class="btn btnSpacing">
              <router-link :to="{ name:'Keno' }">KENO</router-link>
            </li>
            <li class="btn">
              <router-link :to="{ name:'DePK10' }">DePK10</router-link>
            </li>
            <li class="ClearBoth"></li>
          </ul>
        </div>
        <router-view></router-view>
      </div>
    </div>
    <div id="ft">
      <div class="footer"></div>
    </div>
  </div>
</template>

<script>
import './assets/css/normal-index.css';

export default {};
</script>

<style>
</style>
