var moment = require('moment-timezone');

const timeZone = 'Europe/Berlin';
const germanCode = 'de';

moment.locale(germanCode);

const install = (Vue, options) => {

    const dateTimeUtility = {
        months: () => moment.months(),
        days: (year, month) => moment({ year, month }).daysInMonth()
    };

    Vue.prototype.$dateTimeUtility = dateTimeUtility;

    Vue.prototype.$momentTz = value => moment.tz(value, timeZone);

    Vue.filter('currency', value => (value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || ''));

    Vue.filter('formatDateTime', (value, formatter) => (value && moment.tz(value, timeZone).format(formatter)) || '');
};

export default install;