import request from './request';

const lotteryId = 26;

const getLatestLotto6out49Async = () => request.get('depk10/GetLatestLotto6out49');

const getLotto6out49ByDateAsync = (year, month, day) => request.get('depk10/GetLotto6out49ByDate', { year, month, day });

const getKenoByDateAsync = (year, month, day) => request.get('depk10/GetKenoByDate', { year, month, day });

const getLatestKenoAsync = () => request.get('depk10/GetLatestKeno');

const getCurrentIssueAsync = () => request.get('lotto/GetCurrentIssue', { lotteryId });

const getLotto6out49StatisticAsync = () => request.get('depk10/GetLotto6out49Statistic');

const getKenoStatisticAsync = () => request.get('depk10/GetKenoStatistic');

const getHistoryAsync = (begin, end) => request.get('lotto/GetDataByRange', { lotteryId, begin, end, limit: 40, skip: 0 });

const getDePk10StatisticAsync = () => request.get('depk10/GetDePk10Statistic');

const getTrendDataAsync = () => request.get('depk10/GetTrendData');

export default {
    getLatestLotto6out49Async,
    getLotto6out49ByDateAsync,
    getKenoByDateAsync,
    getLatestKenoAsync,
    getCurrentIssueAsync,
    getLotto6out49StatisticAsync,
    getKenoStatisticAsync,
    getHistoryAsync,
    getDePk10StatisticAsync,
    getTrendDataAsync
};