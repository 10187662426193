<template>
  <div id="container">
    <div class="frame-649">
      <div class="logo-649"> </div>
      <div class="title-649">
        <hr color="#000000" size="1" class="hrlien-L">
        <div class="title-text">Gewinnzahlen &amp; Quoten</div>
        <hr color="#000000" size="1" class="hrlien-R">
        <span class="ClearBoth"></span>
      </div>
      <div class="YMD-frame">
        <div class="text-YMD">Andere Ziehung wählen:</div>
        <form>
          <select name="year" class="select-Y" v-model="year" @change="changeYear">
            <option value="" class="yearItem">Bitte wählen Sie</option>
            <option v-for="y in yearSelectOptions" :key="y" :value="y" class="yearItem">
              {{y}}
            </option>
          </select>
          <select name="month" class="select-M" v-model="month" @change="changeMonth">
            <option value="" class="monthItem">Bitte wählen Sie</option>
            <option v-for="m in monthSelectOptions" :key="m.value" :value="m.value" class="monthItem">
              {{m.text}}
            </option>
          </select>
          <select name="day" class="select-D" v-model="day" @change="changeDay">
            <option value="" class="dayItem">Bitte wählen Sie</option>
            <option v-for="d in daysSelectOptions" :key="d" class="dayItem" :value="d">
              {{d}}
            </option>
          </select>
        </form>
        <span class="ClearBoth"></span>
      </div>
      <div class="infor_none" v-if="!lotto6out49"><span>Warten Sie Ziehung</span></div>
      <div class="BallsFrame" v-else>
        <span class="ClearBoth"></span>
        <span v-for="n in lotto6out49.lottoNumbers" class="Balls">
          {{n}}
        </span>
        <span class="text-super">Superzahl</span>
        <span class="Balls superzahi B-S">{{lotto6out49.lottoGreatNum}}</span>
        <span class="text-total">Spieleinsatz:{{lotto6out49.stake | currency}} €</span>
        <span class="ClearBoth"></span>
      </div>
      <div class="table-frame" v-if="lotto6out49">
        <table class="t-table" width="880" border="0" cellspacing="1" cellpadding="1" align="center">
          <thead class="t-head">
            <tr>
              <td class="Gewinnklasse">Gewinnklasse</td>
              <td class="RichtigeZahlen">Richtige Zahlen</td>
              <td class="AnzahlGewinne">AnzahlGewinne</td>
              <td class="Quoten">Quoten</td>
            </tr>
          </thead>
          <tbody class="t-body">
            <tr :class="i % 2 === 0 ? 't-pair':'t-single'" v-for="(q,i) in lotto6out49.quotes">
              <td class="countnum">{{q[0]}}</td>
              <td class="t-align-L">{{q[1]}}</td>
              <td class="t-align-R">{{q[2]}}</td>
              <td class="t-align-R">{{q[3]==='0.00'?'unbesetzt':q[3]}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="Sta-frame">
      <div class="title-Sta">
        <hr color="#000000" size="1" class="hrlien-Sta-L">
        <div class="title-text">Statistik</div>
        <hr color="#000000" size="1" class="hrlien-Sta-R">
        <span class="ClearBoth"></span>
      </div>
      <div class="list-frame">
        <div class="Sta-list-title">Sortiert nach</div>
        <div class="abgne_tab">
          <ul class="tabs ">
            <li :class="{'active':isCurrentStatisticTabIndex(0)}">
              <a href="javascript:void(0);" @click="changeStatisticTabIndex(0)">
                Gewinnzahl
              </a>
            </li>
            <li :class="{'active':isCurrentStatisticTabIndex(1)}">
              <a href="javascript:void(0);" @click="changeStatisticTabIndex(1)">
                Häufigkeit
              </a>
            </li>
          </ul>
          <span class="ClearBoth"></span>
          <div class="tab_container">
            <div class="tab_content" v-show="isCurrentStatisticTabIndex(0)">
              <ol class="all-num-frame" v-if="statistics">
                <li v-for="g in statisticsWinningNumberGroups">
                  <template v-for="item in g">
                    <div class="num-type">
                      <span class="ball-of-num">
                        {{item.num}}
                      </span>
                      <span class="bar-chart">
                        <span class="bar-chart-percentage" :style="{ width: ((item.count / statistics.max) * 100) + '%' }"></span>
                      </span>
                      <span class="nr">
                        {{item.count}}
                      </span>
                    </div>
                  </template>
                </li>
                <span class="ClearBoth D-Block"></span>
              </ol>
            </div>
            <div class="tab_content" v-show="isCurrentStatisticTabIndex(1)">
              <ol class="all-num-frame" v-if="statistics">
                <li v-for="g in statisticsFrequencyGroups">
                  <template v-for="item in g">
                    <div class="num-type">
                      <span class="ball-of-num">
                        {{item.num}}
                      </span>
                      <span class="bar-chart">
                        <span class="bar-chart-percentage" :style="{ width: ((item.count / statistics.max) * 100) + '%' }"></span>
                      </span>
                      <span class="nr">
                        {{item.count}}
                      </span>
                    </div>
                  </template>
                </li>
                <span class="ClearBoth D-Block"></span>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import service from '../service';

export default {
  data() {
    return {
      year: '',
      month: '',
      day: '',
      lotto6out49: null,//{ id: '', additionalNum: 0, insertTime: '', issueNo: '', jackpot: 0, lottoGreatNum: 0, lottoNumbers: [], quotes: [], stake: 0 }
      statisticTabIndex: 0,
      statistics: null // { frequency:[{num: "xx", count: "xx"}...] , max:xxx,winningNumber:[{num: "xx", count: "xx"}...] }
    };
  },
  methods: {
    isCurrentStatisticTabIndex(index) {
      return index === this.statisticTabIndex;
    },
    changeStatisticTabIndex(index) {
      this.statisticTabIndex = index;
    },
    async getLatestLotto6out49Async() {
      var result = await service.getLatestLotto6out49Async();

      if (result) {
        this.year = result.year;
        this.month = parseInt(result.month) - 1;
        this.day = new Date(result.issueNo).getDate();
      }

      this.lotto6out49 = result;
    },
    async getStatisticAsync() {
      var result = await service.getLotto6out49StatisticAsync();
      this.statistics = result;
    },
    changeYear() {
      this.month = '';
      this.day = '';
    },
    changeMonth() {
      this.day = ''
    },
    async changeDay() {
      var year = this.year;
      var month = (parseInt(this.month) + 1).toString();
      var day = this.day;

      if (!year || !month || !day)
        return;

      var result = await service.getLotto6out49ByDateAsync(year, month, day);
      this.lotto6out49 = result;
    },
    async initAsync() {
      await Promise.all([this.getLatestLotto6out49Async(), this.getStatisticAsync()]);
    },
    groupStatistic(source, itemCountPerGroup) {
      if (!source)
        return [];

      var groupCount = Math.ceil(source.length / itemCountPerGroup);

      var result = [];

      for (var i = 0; i < groupCount; i++) {
        var sliceStart = i * itemCountPerGroup;
        var sliceEnd = sliceStart + itemCountPerGroup;

        result.push(source.slice(sliceStart, sliceEnd));
      }

      return result;
    }
  },
  created() {
    this.initAsync();
  },
  computed: {
    yearSelectOptions() {
      var start = 1994;
      var end = 2018;
      var result = [];

      for (var index = start; index <= end; index++)
        result.push(index.toString());

      return result;
    },
    monthSelectOptions() {
      if (!this.year)
        return [];

      var source = this.$dateTimeUtility.months().map((x, i) => ({
        text: x,
        value: i.toString()
      }));

      return source;
    },
    daysSelectOptions() {
      if (!this.year || !this.month)
        return [];

      var start = 1;
      var end = this.$dateTimeUtility.days(this.year, this.month);
      var result = [];

      for (var index = start; index <= end; index++)
        result.push(index.toString());

      return result;
    },
    statisticsWinningNumberGroups() {
      if (!this.statistics)
        return [];

      var itemCountPerGroup = 17;
      return this.groupStatistic(this.statistics.winningNumber, itemCountPerGroup);
    },
    statisticsFrequencyGroups() {
      if (!this.statistics)
        return [];

      var itemCountPerGroup = 17;
      return this.groupStatistic(this.statistics.frequency, itemCountPerGroup);
    },
  }
}
</script>

<style scoped src="../assets/css/normal-6aus49.css">
</style>